<template>
  <div class="mb-100">
    <div
      class="
        container-fluid
        header-content
        mb-40
        pos-relative
        height-res-tab-small-50 height-res-100
      "
    >
      <div class="container-standar pos-relative h-100 h-res-tab-small-80">
        <div class="pos-absolute bottom-10 margin-auto text-center w-100"></div>
      </div>
    </div>
    <div class="cotainer-standar mt--180">
      <div class="container-standar mb-10 pad-20">
        <!-- <h4 class="font-weight-7 text-primary font-20 bor-bot-1 mb-30 pb-4">Profil Desa Hanjuang</h4> -->
        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <div class="row" v-else>
          <div class="col-lg-12">
            <div class="card shadow border-radius-10 pad-10 detail-potensi">
              <h3 class="font-24 text-primary font-weight-7 mt-2 mb-20">
                Detail Repository
              </h3>
              <b-row>
                <b-col md="auto">
                  <b-img
                    :src="repository.cover_book"
                    style="width: 250px; height: 320px;"
                    fluid
                    alt="Image"
                    class="responsive rounded-md"
                  ></b-img>
                </b-col>
                <b-col md="8">
                  <span class="text-primary font-20 font-weight-bold">
                    {{ repository.name }}</span
                  >
                  <b-embed
                    v-if="repository.format_dokumen == 'VIDEO'"
                    type="iframe"
                    aspect="16by9"
                    :src="repository.embed_video"
                    allowfullscreen
                  ></b-embed>
                  <br /><br />
                  <span class="text-dark font-14 font-weight-bold mb-20">
                    Deskripsi : {{ repository.description }}
                  </span>
                  <br />
                  <span class="text-dark font-14 font-weight-bold">
                    Sektor : {{ repository.sektor }}
                  </span>
                  <br />
                  <span class="text-dark font-14 font-weight-bold">
                    Jenis Dokumen : {{ repository.jenis_dokumen }}
                  </span>
                  <br />
                  <span class="text-dark font-14 font-weight-bold">
                    Format Dokumen : {{ repository.format_dokumen }}
                  </span>
                  <br />
                  <span class="text-dark font-14 font-weight-bold">
                    Tanggal publish : {{ repository.published_date }}
                  </span>
                  <br />
                  <br />
                  <b-button
                    v-if="repository.format_dokumen == 'PDF'"
                    variant="primary"
                    @click="download(repository.document_path)"
                    target="_blank"
                  >
                    Download
                  </b-button>
                  <!-- <br>
                        <b-link href="https://d3e22dkm536m45.cloudfront.net/document/2022/03/18/Formulir Pendataan Keluarga SIO-PAPUA.pdf" target="_blank">Download</b-link>
                        <br>
                        <a :href="repository.document_path" target="_blank">Download</a>
                        <b-nav-item
                            class="nav-top"
                            :href="repository.document_path"
                            >
                            <b-navbar-brand
                                class="mr-0 text-primary pt-0 pb-0 font-14 font-weight-5"
                                :href="repository.document_path"
                                target="_blank"
                                >Download</b-navbar-brand
                            >
                            </b-nav-item> -->
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const repository = createNamespacedHelpers("repository");

export default {
  data() {
    return {};
  },
  computed: {
    ...repository.mapState(["repository", "loading"]),
  },
  created() {
    this.getRepositoryById(this.$route.params.id);
  },
  mounted() {
    this.$store.commit("SET_BG", false);
  },
  methods: {
    ...repository.mapActions(["getRepositoryById"]),
    download(link) {
      window.location.href = link;
    },
  },
};
</script>
